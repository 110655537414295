<template>
  <div :class="$style.wrapperReg">
    <div class="text-center">
      <h1 :class="$style.logo">
        <span class="text-white">{{ settings.logo }}<span class="text-white"></span></span>
      </h1>
    </div>
    <div class="card" :class="$style.containerReg">
      <div class="mb-4" :class="$style.title">
        Register now
      </div>
      <a-form-model
        ref="regForm"
        :model="form"
        :rules="rules"
        @submit="handleSubmit"
      >
        <a-form-model-item ref="email" prop="email">
          <a-input v-model="form.email"
                   size="large"
                   placeholder="Email"
                   autocomplete="off"
          >
            <a-icon slot="prefix" type="user" class="text-primary" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="password" prop="password">
          <a-input-password v-model="form.password"
                            size="large"
                            placeholder="Password"
                            type="password"
                            autocomplete="off"
          >
            <a-icon slot="prefix" type="unlock" class="text-primary" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item ref="confirm" prop="confirm">
          <a-input-password v-model="form.confirm"
                            size="large"
                            placeholder="Repeat Password"
                            type="password"
                            autocomplete="off"
          >
            <a-icon slot="prefix" type="unlock" class="text-primary" />
          </a-input-password>
        </a-form-model-item>
        <a-button
          type="primary"
          size="large"
          :class="$style.button"
          :loading="loading"
          htmlType="submit"
        >
          Register
        </a-button>
      </a-form-model>
      <div class="text-center mt-4 mr-1">
        <router-link to="/auth/login" class="kit__utils__link font-size-16">Log in</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'

export default {
  name: 'CuiRegister',
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.processing
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value !== '') {
        if (this.form.confirm !== '') {
          this.$refs.regForm.validateField('confirm')
        }
        callback()
      }
    }
    const validateConfirm = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else if (value !== this.form.password) {
        callback(new Error('Two passwords are different!'))
      } else {
        callback()
      }
    }
    return {
      processing: false,
      form: {
        email: '',
        password: '',
        confirm: '',
      },
      rules: {
        email: [{ required: true, message: 'Please input your email', trigger: 'change' }],
        password: [
          { required: true, message: 'Please input your password', trigger: 'change' },
          { validator: validatePass, trigger: 'change' },
          { min: 6, message: 'password must contain at least 6 characters', trigger: 'change' },
        ],
        confirm: [
          { validator: validateConfirm, trigger: 'change' },
        ],
      },
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.regForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.register()
        } else {
          return false
        }
      })
    },
    register() {
      const url = '/user/register'
      return apiClient.post(url, this.form).then((response) => {
        console.log(response)
        this.$notification.success({
          message: 'You have successfully registered as "' + response.data.data.email + '"',
          description: '',
        })
        this.processing = false
        this.$router.push('/auth/login')
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while registering new account',
          description: error.message,
        })
        this.processing = false
      })
    },

  },
}
</script>
<style lang="scss" module>
  @import "@/components/cleanui/system/Auth/style.module.scss";
</style>
